import React from 'react';
import {
  Card,
  Table,
} from "react-bootstrap";

function RoleCard(props) {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Total: {props.addresses.length} addresses</Card.Subtitle>
        <Card.Text>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {props.addresses.map((e, index) => (
                <tr>
                  <th key={"address" + index}>{index + 1}</th>
                  <td>{e}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default RoleCard;