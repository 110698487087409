import filereaderStream from "filereader-stream";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import csv from "csv-parser";
import Web3 from "web3";
import { useState } from "react";

export const UploadWhitelist = ({
  onChange,
  currentAddress,
  name,
  okText,
  disabled,
  checkReferralAddressIsValid,
  privateSaleInfo,
}) => {
  const [state, setState] = useState({
    errorCsvData: null,
    updateWhitelistInfo: null,
  });

  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <Card.Title tag="h5">{name}</Card.Title>
            <a
              href="https://docs.google.com/spreadsheets/d/1ctw9ljFy8qX_N4W8fokskp-LJwOG3wAccK_yqi4Id04/edit?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              Sheet link
            </a>
            <Form>
              <Form.Group>
                <label>Upload CSV</label>
                <Form.Control
                  type="file"
                  onChange={(event) => {
                    let csvData = [];
                    filereaderStream(event.target.files[0])
                      .pipe(csv())
                      .on("data", (data) => csvData.push(data))
                      .on("end", async () => {
                        let listError = [];
                        let listAddressUser = [];
                        let listRole = [];
                        let listValueRole = [];
                        let listAddressRef = [];
                        let listListExtra = [];
                        let count = 0;
                        for (const inputData of csvData) {
                          count++;
                          // Address
                          let mainAddress = inputData["Main Address"];
                          if (Web3.utils.isAddress(mainAddress)) {
                            listAddressUser.push(mainAddress);
                            mainAddress = Web3.utils.toChecksumAddress(mainAddress);
                          } else {
                            listError.push(
                              count +
                                " Address: " +
                                mainAddress +
                                " wrong data type!"
                            );
                          }

                          // Biggest Role
                          let role = inputData["Role"];
                          listRole.push(Web3.utils.keccak256(role));

                          // Role enable
                          let roleActive = inputData["Active"];
                          if (roleActive === "yes") {
                            listValueRole.push(true);
                          } else if (roleActive === "no") {
                            listValueRole.push(false);
                          } else
                            listError.push(
                              count +
                                " Role active: " +
                                roleActive +
                                " wrong data type!"
                            );

                          // Referral Address
                          let referralAddress = inputData["Referral Address"];
                          if (Web3.utils.isAddress(referralAddress)) {
                            referralAddress = Web3.utils.toChecksumAddress(referralAddress);
                            let errorCheckReferral =
                              await checkReferralAddressIsValid(mainAddress, referralAddress, privateSaleInfo);
                            if (errorCheckReferral !== "") {
                              listError.push(count + " " + errorCheckReferral);
                            } else {
                              listAddressRef.push(referralAddress);
                            }
                          } else {
                            listError.push(
                              count +
                              " Referral Address: " +
                              referralAddress +
                              " wrong data type!"
                            );
                          }
                          // Other address
                          let otherAddress = inputData["Other address"];
                          if (otherAddress) {
                            const listExtraAddresses = otherAddress.split(",");
                            // eslint-disable-next-line no-loop-func
                            listExtraAddresses.forEach((addr) => {
                              if (!Web3.utils.isAddress(addr)) {
                                listError.push(
                                  count +
                                    " Other address: " +
                                    otherAddress +
                                    " wrong data type!"
                                );
                              }
                            });
                            listListExtra.push(listExtraAddresses);
                          } else listListExtra.push([]);
                        }
                        alert(
                          "Total user: " +
                            csvData.length +
                            "\nTotal fail: " +
                            listError.length
                        );
                        setState({
                          errorCsvData: listError,
                          updateWhitelistInfo: onChange?.(
                            listAddressUser,
                            listRole,
                            listValueRole,
                            listAddressRef,
                            listListExtra
                          ),
                        });
                      });
                  }}
                />
                <br />
                {state.errorCsvData?.map((error) => (
                  <Alert variant="danger">{error}</Alert>
                ))}
                <Button
                  onClick={() =>
                    state.updateWhitelistInfo?.send({
                      from: currentAddress,
                    })
                  }
                  disabled={!state.updateWhitelistInfo || disabled}
                >
                  {okText}
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
