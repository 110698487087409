import React from "react";
import { Card, Table } from "react-bootstrap";

function BlacklistCard(props) {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          Total: {props.blacklist.length} addresses
        </Card.Subtitle>
        <Card.Text>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Address</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {props.blacklist.map((e, index) => (
                <tr>
                  <th key={"address" + index}>{index + 1}</th>
                  <td>{e.address}</td>
                  <td>{e.time.toString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default BlacklistCard;
