import React from "react";
import axios from "axios";
import Web3 from "web3";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Form,
  Tab,
  Tabs,
} from "react-bootstrap";
import bigInt from "big-integer";

import "./App.css";

import RoleCard from "./components/roleCard";
import BlacklistCard from "./components/blacklistCard";

import BeinChainAbi from "./abi/BeinChain.json";
import WhitelistAbi from "./abi/Whitelist.json";
import ManagementAbi from "./abi/Management.json";
import listUserWhitelist from "./listUser.json";
import TableCoreTeam from "./views/TableCoreTeam";
import TableCommunity from "./views/TableCommunity";
import Search from "antd/lib/transfer/search";
import _ from "lodash";
import {UploadWhitelist} from "./views/UploadWhitelist";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      web3: null,
      web3Provider: new Web3(
        !process.env.REACT_APP_BSC_ENDPOINT
          ? "https://bsc-dataseed1.binance.org"
          : process.env.REACT_APP_BSC_ENDPOINT
      ),
      currentAddress: null,
      BeinChainContract: null,
      listAdminRole: [],
      listBlackListRole: [],
      listPauserRole: [],
      listTransferRole: [],
      role: {
        admin: "",
        pause: "",
        blacklist: "",
        transfer: "",
      },
      inputRole: "",
      inputAddressRole: "",
      inputAddressBlacklist: "",
      inputBurnAmount: 0,
      blacklistAddress: [],
      listRoleCurrentAddress: [],
      paused: false,

      whitelistContract: null,
      managementContract: null,
      whitelistRole: {
        // role for admin contract whitelist
        admin:
          "0x0000000000000000000000000000000000000000000000000000000000000000",
        updateWhiteList:
          "0xa66cf5a24f9ec43a5c6c494ef0cf458fef30a395858e1d7616445dd08e8591d4",
      },
      privateSaleRole: {
        // role for user contract private
        whiteListPrivateSale:
          "0x289542b9a02c7937d659afe40404ec4f7813e59fc5caf7269eff9a359916cb5c",
        whiteListCoreTeam:
          "0xa02b4c230d71d2b2d883d893e4160247ac13d185cdc94c0e91dad6e97fa25bbb",
      },
      activeAddressRole: {
        isAdmin: false,
        isUpdateWhitelist: false,
      },
      errorCsvData: [],
      updateWhitelistInfo: null,
      privateSaleInfo: {},
      coreTeamInfo: {},
      coreTeamInfoUpdate: [],
      privateSaleInfoUpdate: [],
      coreTeamInfoSearch: [],
      privateSaleInfoSearch: [],
      valueSearch: "",
      isLoadWhitelist: false,
      mapBalanceRef: listUserWhitelist,
      listContractPool: {},
      listContractSplitRefInfo: [],
      listContractSplitRefInfoSearch: [],
    };
  }

  async connectWallet() {
    if (window.ethereum) {
      const ethereum = window.ethereum;
      await ethereum.request({method: "eth_requestAccounts"});
      this.setState({web3: new Web3(ethereum)}, async () => {
        const accounts = await this.state.web3.eth.getAccounts();
        this.setState(
          {
            currentAddress: accounts[0],
            BeinChainContract: new this.state.web3.eth.Contract(
              BeinChainAbi,
              process.env.REACT_APP_BIC_ADDRESS
            ),
            whitelistContract: new this.state.web3.eth.Contract(
              WhitelistAbi,
              process.env.REACT_APP_WHITELIST_ADDRESS
            ),
            managementContract: new this.state.web3.eth.Contract(
              ManagementAbi,
              process.env.REACT_APP_MANAGEMENT_ADDRESS
            ),
          },
          () => {
            this.syncInfoBIC();

            ethereum.on("accountsChanged", (accounts) => {
              this.setState({currentAddress: accounts[0]});
              this.syncInfoAccount();
            });
          }
        );
      });
    }
  }

  async syncInfoAccount() {
    const bicContract = this.state.BeinChainContract;
    let listRole = [];
    let checkRole = await bicContract.methods
      .hasRole(this.state.role.admin, this.state.currentAddress)
      .call();
    if (checkRole) {
      listRole.push("ADMIN");
    }
    checkRole = await bicContract.methods
      .hasRole(this.state.role.blacklist, this.state.currentAddress)
      .call();
    if (checkRole) {
      listRole.push("BLACK_LIST");
    }
    checkRole = await bicContract.methods
      .hasRole(this.state.role.pauser, this.state.currentAddress)
      .call();
    if (checkRole) {
      listRole.push("PAUSER");
    }
    checkRole = await bicContract.methods
      .hasRole(this.state.role.transfer, this.state.currentAddress)
      .call();
    if (checkRole) {
      listRole.push("TRANSFER");
    }
    this.setState({
      listRoleCurrentAddress: listRole,
    });

    this.setState({
      activeAddressRole: {
        isAdmin: await this.state.whitelistContract.methods
          .hasRole(this.state.whitelistRole.admin, this.state.currentAddress)
          .call(),
        isUpdateWhitelist: await this.state.whitelistContract.methods
          .hasRole(
            this.state.whitelistRole.updateWhiteList,
            this.state.currentAddress
          )
          .call(),
      },
    });
  }

  async getListRole(role) {
    const bicContract = this.state.BeinChainContract;
    const countRole = await bicContract.methods.getRoleMemberCount(role).call();
    const listRole = [];
    for (let i = 0; i < countRole; i++) {
      let addr = await bicContract.methods.getRoleMember(role, i).call();
      listRole.push(addr);
    }
    return listRole;
  }

  async syncStatusBIC() {
    const bicContract = this.state.BeinChainContract;
    let statusPause = await bicContract.methods.paused().call();
    this.setState({
      paused: statusPause,
    });
  }

  async syncTopic(topic, contract = "bic") {
    let bscScanAPI = process.env.REACT_APP_BSC_SCAN_API;
    if (!bscScanAPI) {
      bscScanAPI = "https://api.bscscan.com/api";
    }
    try {
      const response = await axios.get(bscScanAPI, {
        params: {
          module: "logs",
          action: "getLogs",
          address:
            contract === "whitelist"
              ? process.env.REACT_APP_WHITELIST_ADDRESS
              : process.env.REACT_APP_BIC_ADDRESS,
          topic0: topic,
          apikey: process.env.REACT_APP_BSC_SCAN_API_TOKEN,
        },
      });
      return response.data.result;
    } catch (error) {
      return [];
    }
  }

  async syncInfoBIC() {
    const bicContract = this.state.BeinChainContract;
    const DEFAULT_ADMIN_ROLE = await bicContract.methods
      .DEFAULT_ADMIN_ROLE()
      .call();
    const BLACK_LIST_ROLE = await bicContract.methods.BLACK_LIST_ROLE().call();
    const PAUSER_ROLE = await bicContract.methods.PAUSER_ROLE().call();
    const TRANSFER_ROLE = await bicContract.methods.TRANSFER_ROLE().call();

    let listAdmin = await this.getListRole(DEFAULT_ADMIN_ROLE);
    let listBlackList = await this.getListRole(BLACK_LIST_ROLE);
    let listPauser = await this.getListRole(PAUSER_ROLE);
    let listTransfer = await this.getListRole(TRANSFER_ROLE);

    let mapEvent = new Map();
    let blockAddressEvent = await this.syncTopic(
      "0x2834dbf2a3f7c45f344e03472acb17bdd1cf712e480a1bccf37cd828f142f6c8"
    );
    blockAddressEvent.forEach((e) => {
      let data = e.data;
      let addr = "0x" + data.substring(26, 66);
      let time = parseInt("0x" + data.substring(122, 131));
      mapEvent.set(addr, time);
    });
    let unBlockAddressEvent = await this.syncTopic(
      "0x2c1d32715179d6320126beb944501764941d7513dbabc5ffac0a133cb0646690"
    );
    if (Array.isArray(unBlockAddressEvent)) {
      unBlockAddressEvent.forEach((e) => {
        let data = e.data;
        let addr = "0x" + data.substring(26, 66);
        let time = parseInt("0x" + data.substring(122, 131));
        if (mapEvent.has(addr)) {
          let tempTime = mapEvent.get(addr);
          if (time > tempTime) {
            mapEvent.delete(addr);
          }
        }
      });
    }

    const listEventBlockAddress = Array.from(mapEvent).map(
      ([keyMap, valueMap]) => {
        return {address: keyMap, time: new Date(valueMap * 1000)};
      }
    );

    this.setState({
      inputRole: DEFAULT_ADMIN_ROLE, //default
      listAdminRole: listAdmin,
      listBlackListRole: listBlackList,
      listPauserRole: listPauser,
      listTransferRole: listTransfer,
      blacklistAddress: listEventBlockAddress,
      role: {
        admin: DEFAULT_ADMIN_ROLE,
        blacklist: BLACK_LIST_ROLE,
        pauser: PAUSER_ROLE,
        transfer: TRANSFER_ROLE,
      },
    });
    this.syncInfoAccount();
    this.syncStatusBIC();
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  addRole() {
    this.state.BeinChainContract.methods
      .grantRole(this.state.inputRole, this.state.inputAddressRole)
      .send({
        from: this.state.currentAddress,
      })
      .then(console.log);
  }

  removeRole() {
    this.state.BeinChainContract.methods
      .revokeRole(this.state.inputRole, this.state.inputAddressRole)
      .send({
        from: this.state.currentAddress,
      })
      .then(console.log);
  }

  pause() {
    this.state.BeinChainContract.methods
      .pause()
      .send({
        from: this.state.currentAddress,
      })
      .then(console.log);
  }

  unpause() {
    this.state.BeinChainContract.methods
      .unpause()
      .send({
        from: this.state.currentAddress,
      })
      .then(console.log);
  }

  blacklist() {
    this.state.BeinChainContract.methods
      .blockAddress(this.state.inputAddressBlacklist)
      .send({
        from: this.state.currentAddress,
      })
      .then(console.log);
  }

  unblacklist() {
    this.state.BeinChainContract.methods
      .unblockAddress(this.state.inputAddressBlacklist)
      .send({
        from: this.state.currentAddress,
      })
      .then(console.log);
  }

  burnBIC() {
    console.log(this.state.inputBurnAmount);
    this.state.BeinChainContract.methods
      .burn(Web3.utils.toWei(this.state.inputBurnAmount))
      .send({
        from: this.state.currentAddress,
      })
      .then(console.log);
  }

  async syncInfoWhitelist() {
  }

  addWhitelistExecutorRole() {
    this.state.whitelistContract.methods
      .grantRole(this.state.inputRole, this.state.inputAddressRole)
      .send({
        from: this.state.currentAddress,
      })
      .then((e) => alert(JSON.stringify(e)));
  }

  removeWhitelistExecutorRole() {
    this.state.whitelistContract.methods
      .revokeRole(this.state.inputRole, this.state.inputAddressRole)
      .send({
        from: this.state.currentAddress,
      })
      .then((e) => alert(JSON.stringify(e)));
  }

  async checkBeforeAddWhitelist(mainAddress, referralAddress, privateSaleInfo) {
    let errString = ""
    if (!Web3.utils.isAddress(referralAddress)) {
      errString = "Referral Address: " + referralAddress + " wrong data type!";
    } else {
      if (!privateSaleInfo[referralAddress]) {
        errString = "Referral Address: " + referralAddress + " is NOT WHITELIST!";
      } else {
        let whitelistCurrent = privateSaleInfo[mainAddress];
        if (whitelistCurrent && whitelistCurrent.addressRef !== referralAddress) {
          // update referrer
          errString = `Main=${mainAddress}; Referral = ${referralAddress}: CAN NOT EDIT REFERRAL OLD WHITELIST USER`;
        }
      }
    }
    return errString;
  }

  async checkBeforeUpdateWhitelist(mainAddress, referralAddress, privateSaleInfo) {
    let errString = "";
    if (!Web3.utils.isAddress(referralAddress)) {
      errString = "Referral Address: " + referralAddress + " wrong data type!";
    } else {
      if (!privateSaleInfo[referralAddress]) {
        errString = "Referral Address: " + referralAddress + " is NOT WHITELIST!";
      }
    }
    return errString;
  }

  formatDatetime(timestamp) {
    const a = new Date(timestamp * 1000);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate().toString().padStart(2, "0");
    const hour = a.getHours().toString().padStart(2, "0");
    const min = a.getMinutes().toString().padStart(2, "0");
    const sec = a.getSeconds().toString().padStart(2, "0");
    return date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  }

  // update totalBIC and whitelist status of list referral
  async updateReferralInfo(referralAddress) {
    let refBIC = await this.state.BeinChainContract.methods
      .balanceOf(referralAddress)
      .call();
    let totalBIC = bigInt(refBIC).divide(bigInt(1e18));
    let isWhiteListPrivateSale = false;
    let mapBalanceTemp = this.state.mapBalanceRef;
    let userWhitelist = await this.state.whitelistContract.methods
      .getUserInfo(
        referralAddress,
        this.state.privateSaleRole.whiteListPrivateSale
      )
      .call();
    let listAddress = [referralAddress];
    if (userWhitelist) {
      let listExtra = userWhitelist[2];
      isWhiteListPrivateSale = userWhitelist[1];
      if (listExtra.length > 0) {
        for (let extra of listExtra) {
          listAddress.push(extra);
          let balanceExtra = await this.state.BeinChainContract.methods
            .balanceOf(extra)
            .call();
          totalBIC = totalBIC.add(bigInt(balanceExtra).divide(bigInt(1e18)));
        }
      }
    }
    for (let address of listAddress) {
      mapBalanceTemp[address] = {
        totalBIC: totalBIC.toJSNumber(),
        whitelist: isWhiteListPrivateSale,
        list: listAddress,
      };
    }

    this.setState({
      mapBalanceRef: mapBalanceTemp,
    });
  }

  coreTeamInfoUpdate = () => {
    return Object.keys(this.state.coreTeamInfo)?.map((address, index) => {
      return {
        stt: index + 1,
        mainAddress: address,
        role: this.state.coreTeamInfo[address].role,
        roleValue: this.state.coreTeamInfo[address].roleValue,
        referralAddress: this.state.coreTeamInfo[address].addressRef,
        otherAddress: this.state.coreTeamInfo[address].extraAddress,
      };
    });
  };

  privateSaleInfo = () => {
    return Object.keys(this.state.privateSaleInfo)?.map((address, index) => {
      return {
        stt: index + 1,
        mainAddress: address,
        role: this.state.privateSaleInfo[address].role,
        roleValue: this.state.privateSaleInfo[address].roleValue,
        referralAddress: this.state.privateSaleInfo[address].addressRef,
        otherAddress: this.state.privateSaleInfo[address].extraAddress,
      };
    });
  };

  getListWhiteList = async () => {
    let blockAddressEvent = await this.syncTopic(
      "0xc2f8a14f0ea508a0149067a1c5781fb1c0c7ee3c19c59f5f3afad31fd35fe26b",
      "whitelist"
    );
    let blockEventReverse = blockAddressEvent.reverse();
    let privateSaleInfo = {};
    let coreTeamInfo = {};
    for (const updateEvent of blockEventReverse) {
      let timestamp = this.formatDatetime(updateEvent.timeStamp);
      const decodeInfo = this.state.web3Provider.eth.abi.decodeParameters(
        [
          "address[]",
          "bytes32[]",
          "bool[]",
          "address[]",
          "address[][]",
          "uint256",
        ],
        updateEvent.data
      );
      for (let i = 0; i < decodeInfo[0].length; i++) {
        let refAddress = decodeInfo[3][i];
        let userAddress = decodeInfo[0][i];
        let roleTemp = decodeInfo[1][i];
        if (roleTemp === this.state.privateSaleRole.whiteListPrivateSale) {
          roleTemp = "WHITELIST_PRIVATE_SALE";
          if (!privateSaleInfo[userAddress]) {
            privateSaleInfo[userAddress] = {
              role: roleTemp,
              roleValue: decodeInfo[2][i],
              addressRef: refAddress,
              extraAddress: decodeInfo[4][i],
              time: timestamp,
            };
          }
        }
        if (roleTemp === this.state.privateSaleRole.whiteListCoreTeam) {
          roleTemp = "WHITELIST_CORE_TEAM";
          if (!coreTeamInfo[userAddress]) {
            coreTeamInfo[userAddress] = {
              role: roleTemp,
              roleValue: decodeInfo[2][i],
              addressRef: refAddress,
              extraAddress: decodeInfo[4][i],
              time: timestamp,
            };
          }
        }
      }
    }
    this.setState({
      privateSaleInfo: privateSaleInfo,
      coreTeamInfo: coreTeamInfo,
    });
    if (this.state.coreTeamInfo) {
      this.setState({
        coreTeamInfoUpdate: this.coreTeamInfoUpdate(),
        privateSaleInfoUpdate: this.privateSaleInfo(),
        coreTeamInfoSearch: this.coreTeamInfoUpdate(),
        privateSaleInfoSearch: this.privateSaleInfo(),
      });
    }
  };

  componentDidMount = () => {
    this.connectWallet();
    this.getListWhiteList();
  };

  componentDidUpdate = () => {
    if (
      this.state.currentAddress &&
      Object.keys(this.state.privateSaleInfo).length === 0
    ) {
      this.getListWhiteList();
    }
  };

  render() {
    return (
      <Container fluid className="distanced" style={{maxWidth: "1440px"}}>
        <Row>
          <Col>
            <h1>BIC admin page</h1>
          </Col>
        </Row>
        {this.state.currentAddress ? (
          ""
        ) : (
          <Row>
            <Col>
              <Button variant="warning" onClick={() => this.connectWallet()}>
                Connect wallet
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>
                  Your address: {this.state.currentAddress}
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  You have {this.state.listRoleCurrentAddress.length} roles
                </Card.Subtitle>
                <Card.Text>
                  <ul>
                    {this.state.listRoleCurrentAddress.map((role) => {
                      return <li>{role}</li>;
                    })}
                  </ul>
                </Card.Text>
                <Card.Text>
                  <ul>Whitelist role:</ul>
                  <ul>
                    - Admin:{" "}
                    {this.state.activeAddressRole.isAdmin ? "👍" : "❌"}
                  </ul>
                  <ul>
                    - Update whitelist:{" "}
                    {this.state.activeAddressRole.isUpdateWhitelist
                      ? "👍"
                      : "❌"}
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Tabs defaultActiveKey="whitelist" transition={false} className="mb-3">
          <Tab eventKey="bic" title="BIC">
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title tag="h5">Assign Role</Card.Title>
                    <Card.Text>
                      <Form>
                        <Form.Group>
                          <Form.Label for="examplePassword">Role</Form.Label>
                          <Form.Control
                            as="select"
                            type="select"
                            name="inputRole"
                            value={this.state.inputRole}
                            onChange={(e) => this.handleChange(e)}
                          >
                            <option value={this.state.role.pauser}>
                              Pause
                            </option>
                            <option value={this.state.role.blacklist}>
                              Blacklist
                            </option>
                            <option value={this.state.role.transfer}>
                              Transfer
                            </option>
                            <option value={this.state.role.admin}>Admin</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label for="examplePassword">Address</Form.Label>
                          <Form.Control
                            type="string"
                            name="inputAddressRole"
                            value={this.state.inputAddressRole}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </Form.Group>
                      </Form>
                    </Card.Text>
                    <Button onClick={() => this.addRole()}>Add</Button>{" "}
                    <Button variant="danger" onClick={() => this.removeRole()}>
                      Remove
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Pause network</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Current state: {this.state.paused ? "Paused" : "Normal"}
                    </Card.Subtitle>
                    {this.state.paused ? (
                      <Button onClick={() => this.unpause()}>Unpause</Button>
                    ) : (
                      <Button onClick={() => this.pause()}>Pause</Button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Blacklist:</Card.Title>
                    <Card.Text>
                      <Form>
                        <Form.Group>
                          <Form.Label>Address:</Form.Label>
                          <Form.Control
                            type="string"
                            name="inputAddressBlacklist"
                            value={this.state.inputAddressBlacklist}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </Form.Group>
                      </Form>
                    </Card.Text>
                    <Button variant="danger" onClick={() => this.blacklist()}>
                      Blacklist
                    </Button>{" "}
                    <Button onClick={() => this.unblacklist()}>
                      Un-blacklist
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>User:</Card.Title>
                    <Card.Text>
                      <Form>
                        <Form.Group>
                          <Form.Label>Burn:</Form.Label>
                          <Form.Control
                            name="inputBurnAmount"
                            type="number"
                            value={this.state.inputBurnAmount}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </Form.Group>
                      </Form>
                    </Card.Text>
                    <Button variant="danger" onClick={() => this.burnBIC()}>
                      Burn
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <RoleCard
                  title="ADMIN role"
                  addresses={this.state.listAdminRole}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RoleCard
                  title="BLACK_LIST role"
                  addresses={this.state.listBlackListRole}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RoleCard
                  title="PAUSER role"
                  addresses={this.state.listPauserRole}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RoleCard
                  title="TRANSFER role"
                  addresses={this.state.listTransferRole}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <BlacklistCard
                  title="Addresses are in Blacklist"
                  blacklist={this.state.blacklistAddress}
                />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="whitelist" title="Whitelist">
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title tag="h5">Assign Executor Role</Card.Title>
                    <Card.Text>
                      <a
                        href="https://app.clickup.com/3649385/v/dc/3fbv9-31027/3fbv9-91985"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Tutorial
                      </a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <UploadWhitelist
              name={"ADD whitelist (CAN NOT EDIT Referral address):"}
              onChange={
                this.state.managementContract?.methods?.addWhitelistInfo
              }
              currentAddress={this.state.currentAddress}
              okText={"ADD whitelist"}
              disabled={Object.keys(this.state.privateSaleInfo).length === 0}
              checkReferralAddressIsValid={this.checkBeforeAddWhitelist}
              privateSaleInfo={this.state.privateSaleInfo}
            />
            <UploadWhitelist
              name={"UPDATE whitelist:"}
              onChange={
                this.state.managementContract?.methods?.updateWhitelistInfo
              }
              currentAddress={this.state.currentAddress}
              okText={"UPDATE whitelist"}
              disabled={Object.keys(this.state.privateSaleInfo).length === 0}
              checkReferralAddressIsValid={this.checkBeforeUpdateWhitelist}
              privateSaleInfo={this.state.privateSaleInfo}
            />
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title tag="h5">Whitelist:</Card.Title>
                    <Button>Load</Button>
                    <Search
                      placeholder="input search text"
                      allowClear
                      onChange={(e) => {
                        this.setState({
                          coreTeamInfoSearch:
                            this.state.coreTeamInfoUpdate.filter(
                              (item) =>
                                item.mainAddress
                                  .toLowerCase()
                                  .trim()
                                  .search(
                                    e.target.value.toLowerCase().trim()
                                  ) !== -1 ||
                                item.referralAddress
                                  .toLowerCase()
                                  .trim()
                                  .search(
                                    e.target.value.toLowerCase().trim()
                                  ) !== -1 ||
                                item.otherAddress.find(
                                  (item) =>
                                    item
                                      .toLowerCase()
                                      .trim()
                                      .search(
                                        e.target.value.toLowerCase().trim()
                                      ) !== -1
                                )
                            ),
                          privateSaleInfoSearch:
                            this.state.privateSaleInfoUpdate.filter(
                              (item) =>
                                item.mainAddress
                                  .toLowerCase()
                                  .trim()
                                  .search(
                                    e.target.value.toLowerCase().trim()
                                  ) !== -1 ||
                                item.referralAddress
                                  .toLowerCase()
                                  .trim()
                                  .search(
                                    e.target.value.toLowerCase().trim()
                                  ) !== -1 ||
                                item.otherAddress.find(
                                  (item) =>
                                    item
                                      .toLowerCase()
                                      .trim()
                                      .search(
                                        e.target.value.toLowerCase().trim()
                                      ) !== -1
                                )
                            ),
                          valueSearch: e.target.value,
                          listContractSplitRefInfoSearch:
                            this.state.listContractSplitRefInfoSearch.filter(
                              (item) =>
                                item.contractAddress
                                  .toLowerCase()
                                  .trim()
                                  .search(
                                    e.target.value.toLowerCase().trim()
                                  ) !== -1 ||
                                item.user1.userAddress
                                  .toLowerCase()
                                  .trim()
                                  .search(
                                    e.target.value.toLowerCase().trim()
                                  ) !== -1 ||
                                item.user2.userAddress
                                  .toLowerCase()
                                  .trim()
                                  .search(
                                    e.target.value.toLowerCase().trim()
                                  ) !== -1
                            ),
                        });

                        if (e.target.value === "") {
                          this.setState({
                            coreTeamInfoSearch: this.coreTeamInfoUpdate(),
                            privateSaleInfoSearch: this.privateSaleInfo(),
                            listContractSplitRefInfoSearch:
                            this.state.listContractSplitRefInfo,
                          });
                        }
                      }}
                    />
                    <TableCoreTeam
                      coreTeamInfo={this.state.coreTeamInfoSearch}
                      valueSearch={this.state.valueSearch}
                    />
                    <TableCommunity
                      privateSaleInfo={this.state.privateSaleInfoSearch}
                      valueSearch={this.state.valueSearch}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

export default App;
