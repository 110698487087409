import React from "react";
import { Table } from "antd";
import Highlighter from "react-highlight-words";

const TableCommunity = (props) => {
  const columns = [
    {
      title: "Stt",
      dataIndex: "stt",
      sorter: (a, b) => a.stt - b.stt,
    },
    {
      title: "Main Address",
      dataIndex: "mainAddress",
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[props.valueSearch]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Active",
      dataIndex: "roleValue",
      render: (roleValue) => {
        return <span>{roleValue ? "👍" : "❌"}</span>;
      },
    },
    {
      title: "Referral Address",
      dataIndex: "referralAddress",
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[props.valueSearch]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "Other Address",
      dataIndex: "otherAddress",
      render: (otherAddress) => {
        return otherAddress.map((text) => (
          <p>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[props.valueSearch]}
              autoEscape
              textToHighlight={text ? text.toString() : { text }}
            />
          </p>
        ));
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={props.privateSaleInfo}
      rowKey="stt"
      pagination={{ pageSize: 5 }}
    />
  );
};

export default TableCommunity;
